<template>
  <div>
    <div class="mcion_swiper swiper">
      <swiper :options="swiperOption" v-if="swiperList.length">
        <swiper-slide v-for="(item, index) in swiperList" :key="index">
          <a :href="item.jump_link" target="_blank">
            <img :src="item.img_url" width="100%" />
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <van-loading
      type="spinner"
      color="#ffa019"
      class="s_loading"
      v-if="isLoading"
    />
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "McionBanner",
  components: {},
  data() {
    return {
      isLoading: true,
      swiperList: [],
      swiperOption: {
        pagination: { el: ".swiper-pagination" },
        paginationClickable: true,
        grabCursor: true,
        loop: true,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        coverflow: {
          rotate: 30,
          stretch: 10,
          depth: 60,
          modifier: 2,
          slideShadows: true,
        },
        observeParents: true,
      },
    };
  },
  mounted() {
    this.getSwiperList()
  },
  methods: {
    getSwiperList() {
      const datas = {
        local: 'TW',
        position:'header',
      }
      this.getHttp(this, datas, "/vx/banner/list", function (obj, data) {
        if (data.status === 2000) {
          obj.swiperList = data.data
        } else {
          obj.$layer.msg(data.message);
        }
        obj.isLoading = false;
      });

      // axios
      //   .get("https://api.gamemorefun.com/front/mobile/banner")
      //   .then((res) => {
      //     let d = res.data;
      //     d.status == 2000
      //       ? (this.swiperList = d.data)
      //       : this.$layer.msg(d.message);
      //     this.isLoading = false;
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.s_loading {
  position: absolute !important;
  z-index: 999;
  width: 1rem;
  height: 1rem;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  color: #ffa019 !important;
}
video {
  width: 100%;
  height: 100%;
}
.mcion_swiper {
  width: 100%;
  height: 2.93rem !important;
  background: #ccc;
  // height: calc(100% - 1.2rem);
}
.swiper-container, .swiper-container img{
  width: 100%;
  height: 100%;
}
.swiper ::v-deep .swiper-pagination-bullet-active {
  background: #ffa019 !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  // bottom: 2rem;
  transition: all 0.6s;
}
.mcion_swiper {
  width: 100%;
  height: 2.93rem !important;
  background: #ccc;
}
</style>