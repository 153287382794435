<template>
  <div class="tab_my container_role">
    <div class="list-view scroll-box" v-if="roleList">
      <mescroll
        ref="mescroll"
        :down="mescrollDown"
        :up="mescrollUp"
        @init="mescrollInit"
      >
        <div class="list-box" v-for="(list, index) in roleList" :key="index">
          <div class="list-text">
            <span class="name ep-one">角色：{{ list.role_name }}</span>
            <span class="type ep-one"
              >遊戲：{{ list.game.real_name }} {{ list.server_name }}</span
            >
            <div class="text">
              <div
                class="button"
                @click="debounceSetRole(list.id)"
                v-if="list.is_default === 2"
              >
                設爲默認
              </div>
            </div>
          </div>
        </div>
      </mescroll>
    </div>
    <div class="loading noOrder" v-show="noData">
      <div>
        <img src="../assets/images/no-order.png" class="nobuy" />
      </div>
      <div class="tipsText">您還沒有下單哦~</div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import mescroll from "mescroll.js/mescroll.vue";
export default {
  name: "roleList",
  components: { mescroll },
  data() {
    return {
      mescroll: null,
      mescrollDown: {
        auto: false,
        textOutOffset: "釋放更新",
        textLoading: "Loading...",
        callback: this.downCallback,
      },
      mescrollUp: {
        callback: this.getroleList,
        page: {
          num: 0,
          size: 8,
          time: null,
        },
        auto: true,
        noMoreSize: 8,
        htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        htmlLoading:
          '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">Loading...</p>',
      },
      roleList: [],
      noData: false,
    };
  },
  created() {
    this.debounceSetRole = _.debounce((e) => {
      this.handleSetRole(e);
    }, 300);
  },
  mounted() {},
  methods: {
    debounceSetRole(e) {
      this._.debounce(this.handleSetRole(e), 1000, false);
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    downCallback(mescroll) {
      this.roleList = [];
      mescroll.resetUpScroll();
    },
    getroleList(page) {
      if (!page) {
        return false;
      }
      let datas = {
        page: page.num,
        page_size: page.size,
      };
      this.getHttp(this, datas, "/h5/roles", function (obj, data) {
        if (data.status == 2000) {
          let arr = data.data.table_list;

          if (page.num === 1) obj.roleList = [];
          obj.roleList = obj.roleList.concat(arr);

          if (obj.roleList.length == 0) {
            obj.noData = true;
          } else {
            obj.noData = false;
          }

          obj.$nextTick(() => {
            obj.mescroll.endSuccess(arr.length);
          });
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
    handleSetRole(e) {
      let datas = {
        id: e,
      };
      this.postHttp(this, datas, "/h5/role/set", function (obj, data) {
        if (data.status == 2000) {
          obj.getroleList({ num: 1, size: 8, time: null });
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab_my {
  overflow-x: hidden;
}
.scroll-box {
  width: 100%;
  position: fixed;
  top: 4.6rem;
  bottom: 0.6rem;
  height: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0.6rem;
}
.iconfont {
  position: absolute;
  right: 0.8667rem;
  bottom: 0.7333rem;
}

.iconfont_vx {
  position: absolute;
  right: 0.2667rem;
  bottom: 1.2133rem;
  font-size: 0.3467rem;
}

.price_vx {
  position: absolute;
  right: 0.2667rem;
  bottom: 0.36rem;
  font-size: 0.4267rem;
}
.addLine1 {
  cursor: pointer;
}

.loading {
  width: 100%;
  text-align: center;
  font-size: 0.2933rem;
  margin-top: 0.1333rem;
  color: #666666;
}

.noOrder {
  margin: 1.8667rem auto;
  text-align: center;
  font-size: 0.4533rem;
}

.nobuy {
  width: 5rem;
  height: 5rem;
}

.nobuy_order {
  width: 3.4667rem;
  height: 3.3733rem;
}

.tipsText {
  display: block;
  text-align: center;
  color: #9b9b9b;
  font-size: 0.3733rem;
  margin-top: 0.2rem;
}

.addLine {
  text-decoration: underline;
}

.topfixed {
  width: 100%;
  height: 0.6rem;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  color: #fff;
  background-color: #f6b45e;
  text-align: center;
  font-size: 0.3467rem;
  vertical-align: middle;
  line-height: 0.6rem;
  padding: 0.1333rem 0 0.04rem;
}

.goods_success {
  color: #f59a23;
}

.goods_fail {
  color: #d9001b;
}

.container_role {
  margin-left: 0;
  padding-bottom: 2%;
  background: #fff;
}
.container_role .list-view:nth-of-type(1) {
  border-top: 0;
}

.container_role .list-box {
  text-align: left;
  margin: 0 0.0667rem;
  padding: 0.1333rem 0.3333rem;
  border-bottom: 0.02rem solid #eee;
  transition: background 0.6s;
  &:hover {
    background: #edeef0;
  }
}

.container_role .list-box:first-child {
  border-top: 0.02rem solid #eee;
}

.list-images img {
  width: 2.2667rem;
  height: 2.2667rem;
  border-radius: 0.3333rem;
}

.container_role .list-text {
  position: relative;
  font-size: 0.1867rem;
  color: #666666;
}
.container_role .list-text .name {
  display: block;
  width: 6.6667rem;
  padding: 0.0667rem 0;
  color: #181818;
  font-size: 0.4267rem;
}

.container_role .list-text .type {
  display: block;
  width: 6.6667rem;
  padding: 0.2rem 0 0.2rem 0;
  color: #9b9b9b;
  font-size: 0.4rem;
}

.container_role .list-text .text {
  color: #aaaaaa;
  height: 0.7733rem;
  vertical-align: bottom;
  line-height: 0.7733rem;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.3867rem;
}

.container_role .list-text .price {
  font-size: 0.4rem;
  color: #181818;
}

.container_role .list-text .button {
  width: 1.8533rem;
  height: 0.7733rem;
  line-height: 0.7733rem;
  border-radius: 0.08rem;
  font-size: 0.3733rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 0.0267rem solid #ffa019;
  transition: all 0.2s;
  cursor: pointer;
}

@media (any-hover: hover) {
  .container_role .list-text .button:hover {
    color: #fff;
    background-color: #ffa019;
  }
}

.container_role .login {
  display: flex;
  align-items: center;
  height: 1.3333rem;
}

.container_role .login .fr {
  margin-left: auto;
  margin-right: 0.2rem;
}

.container_role .login_text {
  padding-top: 0.1333rem;
  font-weight: bold;
}

.container_role .icon_image {
  display: inline-block;
  width: 0.6667rem;
  height: 0.6667rem;
  margin-right: 0.1333rem;
  margin-left: 0.1333rem;
  padding-top: 0.0667rem;
}
</style>
