<template>
  <div id="app">
    <router-view></router-view>
    <home-bot></home-bot>
  </div>
</template>

<script>
import HomeBot from './components/HomeBot.vue'
export default {
  name: 'App',
  components: {
    HomeBot,
  },
  data() {
    return {}
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 750px;
  min-width: 320px;
  margin: 0 auto;
}
</style>
