<template>
  <div class="tab_my container_order">
    <div class="list-view scroll-box" v-if="orderList">
      <mescroll
        ref="mescroll"
        :down="mescrollDown"
        :up="mescrollUp"
        @init="mescrollInit"
      >
        <div class="list-box" v-for="(list, index) in orderList" :key="index">
          <div class="list-images">
            <img :src="list.goods_img" />
          </div>
          <div class="list-text">
            <div class="text">
              <span class="price" @click="handleCopy(list.transaction_id)">
                單號:
                <span class="addLine1">{{ list.transaction_id }}</span>
              </span>
            </div>
            <div class="name">
              游戲：{{ list.game.real_name }}
              <div
                class="iconfont_vx goods_success"
                v-if="list.exchange_status == true"
              >
                儲值成功
              </div>
              <div class="iconfont_vx goods_fail" v-else>發貨失敗</div>
            </div>
            <div class="name">
              角色：{{ list.role }}
              <span class="type ep-one price_vx">${{ list.amount }}</span>
            </div>
            <div class="name">
              支付時間: {{ list.game.created_at }}
            </div>
          </div>
        </div>
      </mescroll>
    </div>
    <div class="loading noOrder" v-show="noData">
      <div>
        <img src="../assets/images/no-order.png" class="nobuy" />
      </div>
      <div class="tipsText">您還沒有下單哦~</div>
    </div>
  </div>
</template>
<script>
import mescroll from "mescroll.js/mescroll.vue";
export default {
  name: "OrderList",
  components: { mescroll },
  data() {
    return {
      mescroll: null,
      mescrollDown: {
        auto: false,
        textOutOffset: "釋放更新",
        textLoading: "Loading...",
        callback: this.downCallback,
      },
      mescrollUp: {
        callback: this.getOrderList,
        page: {
          num: 0,
          size: 6,
        },
        auto: true,
        noMoreSize: 6,
        htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        htmlLoading:
          '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">Loading...</p>',
      },
      orderList: [],
      noData: false,
    };
  },
  mounted() {},
  methods: {
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    downCallback(mescroll) {
      this.orderList = [];
      mescroll.resetUpScroll();
    },
    getOrderList(page) {
      if (!page) {
        return false;
      }
      let datas = {
        page: page.num,
        page_size: page.size,
      };
      this.getHttp(this, datas, "/h5/order/list", function (obj, data) {
        if (data.status === 2000) {
          let arr = data.data.order_list;

          if (page.num === 1) obj.orderList = [];
          obj.orderList = obj.orderList.concat(arr);

          if (obj.orderList.length == 0) {
            obj.noData = true;
          } else {
            obj.noData = false;
          }

          obj.$nextTick(() => {
            obj.mescroll.endSuccess(arr.length);
          });
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
    handleCopy(e) {
      this.$copyText(e)
        .then(() => {
          this.$layer.msg("复制成功！");
        })
        .catch(() => {
          this.$layer.msg("复制失败！");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes tabani {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}
.tab_my {
  overflow-x: hidden;
  transition: all 0.6s;
}
.scroll-box {
  width: 100%;
  position: fixed;
  top: 4.4rem;
  bottom: 0.6rem;
  height: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0.6rem;
}
.iconfont {
  position: absolute;
  right: 0.8667rem;
  bottom: 0.7333rem;
}

.iconfont_vx {
  font-size: 0.3467rem;
}

.price_vx {
  font-size: 0.4267rem;
}
.addLine1 {
  cursor: pointer;
}
.loading {
  width: 100%;
  text-align: center;
  font-size: 0.2933rem;
  margin-top: 0.1333rem;
  color: #666666;
}

.noOrder {
  margin: 1.8667rem auto;
  text-align: center;
  font-size: 0.4533rem;
}

.nobuy {
  width: 5rem;
  height: 5rem;
}

.nobuy_order {
  width: 3.4667rem;
  height: 3.3733rem;
}

.tipsText {
  display: block;
  text-align: center;
  color: #9b9b9b;
  font-size: 0.3733rem;
  margin-top: 0.2rem;
}

.addLine {
  text-decoration: underline;
}

.topfixed {
  width: 100%;
  height: 0.6rem;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  color: #fff;
  background-color: #f6b45e;
  text-align: center;
  font-size: 0.3467rem;
  vertical-align: middle;
  line-height: 0.6rem;
  padding: 0.1333rem 0 0.04rem;
}

.goods_success {
  color: #f59a23;
}

.goods_fail {
  color: #d9001b;
}

/* 订单列表 */
.container_order {
  padding-bottom: 2rem;
  margin-top: 5%;
}
.container_role {
  margin-left: 0;
  padding-bottom: 2%;
  background: #fff;
}
.tab_my {
  overflow: hidden;
  animation-duration: 0.4s;
}
.container_order .list-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 96%;
  margin: 0 auto;
  padding: 0.2rem;
  margin-top: 0.24rem;
  text-align: left;
  border: 1px solid #edeef0;
  background: #fff;
  border-radius: 0.0667rem;
  transition: background 0.6s;
  &:hover {
    background: #edeef0;
  }
}

.container_order .list-images {
  margin-right: 0.32rem;
  margin-left: 0.1333rem;
}

.container_order .list-images image {
  width: 2.2667rem;
  height: 2.2667rem;
  border-radius: 0.2667rem;
}

.container_order .list-text {
  flex: 0.98;
  position: relative;
  font-size: 14px;
  color: #666666;
}

.container_order .list-text .name {
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  color: #9b9b9b;
  font-size: 0.3467rem;
  margin-top: 0.24rem;
}

.container_order .list-text .type {
  display: block;
  padding: 0.0667rem 0 0.0667rem 0;
  color: #181818;
  font-size: 0.4133rem;
}

.container_order .list-text .txt {
  padding: 0.0667rem;
  color: #aaaaaa;
}

.container_order .list-text .price {
  font-size: 0.3467rem;
  color: #9b9b9b;
}

.list-images img {
  width: 2.2667rem;
  height: 2.2667rem;
  border-radius: 0.3333rem;
}

.loading {
  width: 100%;
  text-align: center;
  font-size: 0.2933rem;
  margin-top: 0.1333rem;
  color: #9b9b9b;
}

.noOrder {
  margin: 1.1333rem auto;
  text-align: center;
  font-size: 0.4533rem;
}

.noLogin {
  text-align: center;
  height: 3.4rem;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -4.7rem;
}

.noLogin text {
  display: block;
}

.noLogin .title {
  display: block;
  font-size: 0.6133rem;
  margin: 0.6rem 0 0;
}

.nobuy_merge {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}
</style>
