<template>
  <div class="wrapper">
    <home-swiper></home-swiper>
  </div>
</template>
<script>
import HomeSwiper from "../components/HomeSwiper.vue";
export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    HomeSwiper,
  },
  mounted() {
    this.thirdLogin();
  },
  methods: {
    thirdLogin() {
      this.account = this.$utils.getUrlKey("account");
      this.name = this.$utils.getUrlKey("name");
      this.token = this.$utils.getUrlKey("token");
      this.type = this.$utils.getUrlKey("type");
      if (this.account && this.name && this.token && this.type) {
        let datas = {
          account: this.account,
          token: this.token,
        };
        this.postHttp(this, datas, "/h5/login/other", function (obj, data) {
          if (data.status === 2000) {
            let d = data.data;
            localStorage.setItem("token", d.token);
            localStorage.setItem("account", d.account);
            window.location.href = 'https://store.hermesgames.com.tw';
          } else {
            obj.$layer.msg(data.message);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  height: calc(100% - 1.2rem);
}
</style>