<template>
  <div class="container">
    <div v-if="!token">
      <div class="noLogin">
        <img src="../assets/images/head.png" class="nobuy_merge" />
        <span class="title">歡迎使用MoreFun商城</span>
        <span class="tipsText">登入后即可購買心儀的商品喲~</span>
        <div class="dialog_wrapper">
          <form>
            <div class="account_v">
              <img src="../assets/images/l_account.png" />
              <input
                type="text"
                name="form.account"
                placeholder="賬號"
                v-model="form.account"
                placeholder-style="color:#cfa069;"
              />
            </div>
            <div class="password_v">
              <img src="../assets/images/l_password.png" />
              <input
                type="password"
                name="form.password"
                v-model="form.password"
                placeholder="密碼"
                placeholder-style="color:#cfa069;"
              />
            </div>
            <a href="javascript:;" class="button" @click="debounceLogin"
              >立即登入</a
            >
            <div class="btn_third_login">
              <a href="javascript:;" @click="thirdLoginPop('facebook')"></a>
              <a href="javascript:;" @click="thirdLoginPop('google')"></a>
              <a href="javascript:;" @click="thirdLoginPop('apple')"></a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="mergeWrapper" v-else>
      <div class="merge">
        <span class="login_out" v-if="token" @click="loginOut">登出</span>
        <img src="../assets/images/head.png" />
        <span class="account">{{ form.account }}</span>
        <span class="tip"
          >如遇任何訂單問題，請聯繫客服：service@iclockwork.com</span
        >
      </div>

      <div class="tab">
        <div
          class="tab_item"
          :class="curId == 0 ? 'c_a' : ''"
          @click="handleTab(0)"
        >
          訂單
        </div>
        <div
          class="tab_item"
          :class="curId == 1 ? 'c_a' : ''"
          @click="handleTab(1)"
        >
          角色
        </div>
        <span :class="curId == 0 ? 'line_mar_l' : 'line_mar_r'"></span>
      </div>

      <div v-if="curId == 0">
        <order-list ref="order"></order-list>
      </div>

      <div v-else>
        <roles-list ref="role"></roles-list>
      </div>
    </div>

    <template>
      <mt-popup
        v-model="options.popVisible_game"
        position="bottom"
        :closeOnClickModal="false"
        :lockScroll="true"
      >
        <div class="popup-container" @touchmove.prevent>
          <mt-picker
            :slots="slots"
            :showToolbar="true"
            ref="picker_game"
            valueKey="real_name"
            @change="onValuesChange"
          >
            <span
              @click="cancel()"
              class="mint-datetime-action mint-datetime-cancel"
              >取消</span
            >
            <span
              @click="confirm()"
              class="mint-datetime-action mint-datetime-confirm"
              >確認</span
            >
          </mt-picker>
        </div>
      </mt-popup>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import OrderList from "../components/OrderList.vue";
import RolesList from "../components/RolesList.vue";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
export default {
  name: "MyInfo",
  components: { OrderList, RolesList },
  data() {
    return {
      token: "",
      page: 1,
      pages: 0,
      loading: false,
      loadingMore: false,
      loadingMoreComplete: false,
      noData: false,
      noData_role: false,
      curId: 0,
      form: {
        local: "TW",
        account: "",
        password: "",
      },
      platFormType: "",
      gamesList: [],
      url: "",
      options: {
        popVisible_game: false,
      },
      slots: [
        {
          flex: 1,
          values: [],
          className: "slot1",
          textAlign: "center",
        },
      ],
    };
  },
  created() {
    this.debounceLogin = _.debounce(() => {
      this.morefunLogin();
    }, 300);
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.token = localStorage.getItem("token");
    }
    if (localStorage.getItem("account")) {
      this.form.account = localStorage.getItem("account");
    }
  },
  methods: {
    onValuesChange: function (picker, values) {
      if (values[0] > values[1]) {
        picker.setSlotValue(1, values[0]);
      }
    },
    cancel() {
      this.options.popVisible_game = false;
      this.gamesList = "";
      this.slots[0].values = "";
    },
    // 三方登录回调
    // 测试服：https://km.gamemorefun.net/store
    confirm() {
      this.options.popVisible_game = false;
      let values = this.$refs.picker_game.getValues();
      const appid = values[0].appid;
      this.valueSelected_game = values[0].name;
      if (values.length > 0 && this.platFormType) {
        switch (this.platFormType) {
          case "facebook":
            this.url =
              "https://sdk.gamemorefun.com/mobile/v1/passport/facebook/5?app_id=" +
              appid +
              "&redirect_url=https%3A%2F%2Fstore.hermesgames.com.tw%2F%23%2F";
            break;
          case "google":
            this.url =
              "https://sdk.gamemorefun.com/rest/v2/passport/google/5?app_id=" +
              appid +
              "&redirect_url=https%3A%2F%2Fstore.hermesgames.com.tw%2F%23%2F";
            break;
          case "apple":
            this.url =
              "https://sdk.gamemorefun.com/rest/v2/passport/apple/5?app_id=" +
              appid +
              "&redirect_url=https%3A%2F%2Fstore.hermesgames.com.tw%2F%23%2F";
            break;
        }
      }
      window.location.href = this.url;
      this.gamesList = "";
      this.slots[0].values = "";
    },
    openPicker_game(type) {
      this.type_picker = type;
      this.getUserGamesList();
    },
    getUserGamesList() {
      let datas = {
        local: "TW",
      };
      this.getHttp(this, datas, "/h5/game/login", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          if (d.length == 0) {
            obj.$layer.msg("您還沒有已註冊的遊戲!");
          } else {
            obj.gamesList = d;
            obj.slots[0].values = obj.gamesList;
            obj.valueSelected_game = obj.gamesList[0].name;
          }
        }
      });
    },
    thirdLoginPop(t) {
      this.platFormType = t;
      this.options.popVisible_game = true;
      this.getUserGamesList();
    },
    debounceLogin() {
      this._.debounce(this.morefunLogin, 1000, false);
    },
    morefunLogin() {
      if (!this.form.account) {
        this.$layer.msg("請輸入賬號");
        return;
      }
      if (!this.form.password) {
        this.$layer.msg("請輸入密碼");
        return;
      }
      let datas = this.form;
      this.postHttp(this, datas, "/h5/login", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          obj.token = d.token;
          obj.form.account = d.account;
          obj.isLoginDialog = false;
          obj.isMask = false;
          obj.isLogined = true;
          localStorage.setItem("token", d.token);
          localStorage.setItem("account", d.account);
          obj.curId = 0;
					obj.$router.go(-1)
          obj.$nextTick(() => {
            obj.$refs.order.getOrderList();
          });
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
    handleTab(t) {
      this.curId = t;
    },
    loginOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("account");
      this.token = "";
      this.type = 0;
      this.account = "請登入賬號";
      this.$layer.msg("已登出");
    },
  },
};
</script>

<style lang="scss" scoped>
.merge::v-deep .mescroll {
  overflow-x: hidden;
}
.merge {
  position: relative;
  padding: 0.5rem 0;
  background: #f9f9f9;
  text-align: center;
}

.merge img {
  width: 1.0667rem;
  height: 1.0667rem;
  margin: 0 auto 0.2667rem;
}

.merge img,
.merge span {
  display: block;
}

.merge .account {
  font-size: 0.4267rem;
  font-weight: bolder;
  margin: 0.1333rem;
  color: #333333;
}

.merge .tip {
  font-size: 0.3467rem;
  color: #7f7f7f;
  margin-top: 0.3067rem;
}

.tab {
  color: #f0f8fa;
}

.tab {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 1.1733rem;
  line-height: 1.1733rem;
  background: #fff;
  box-shadow: 0 0.0667rem 0.2667rem 0.0667rem #eeeeee;
}

.tab .tab_item {
  display: inline-block;
  width: 50%;
  color: #7f7f7f;
  font-size: 0.3733rem;
  text-align: center;
  box-sizing: content-box;
  background: #fff;
  transition: all 0.9s;
  cursor: pointer;
}

.active {
  transition: all 0.9s;
  color: #ffa019 !important;
}

.tab span {
  display: block;
  width: 50%;
  height: 0.0633rem;
  position: absolute;
  bottom: -0.0267rem;
  left: 0;
  background-image: linear-gradient(to right, #fff, #ffa019, #fff);
}

.line_mar_l {
  color: #ffa019;
  margin-left: 0;
  transition: margin-left 0.2s;
}

.line_mar_r {
  color: #ffa019;
  margin-left: 50%;
  transition: margin-left 0.2s;
}

.c_a {
  color: #ffa019 !important;
}

.button {
  float: right;
  width: 1.8533rem;
  height: 0.7733rem;
  line-height: 0.7733rem;
  border-radius: 0.08rem;
  font-size: 0.4rem;
  color: #ffff;
  text-align: center;
  background-color: #ffa019;
  cursor: pointer;
}

.orange {
  font-size: 1.0667rem;
}

.gray {
  background-color: #959595;
}

.iconfont {
  position: absolute;
  top: 0.6rem;
  right: 0.8667rem;
  bottom: 0.7333rem;
}

.iconfont_vx {
  position: absolute;
  right: 0.2667rem;
  bottom: 1.2133rem;
  font-size: 0.3467rem;
}

.price_vx {
  position: absolute;
  right: 0.2667rem;
  bottom: 0.36rem;
  font-size: 0.4267rem;
}
.addLine1 {
  cursor: pointer;
}
.loading {
  width: 100%;
  text-align: center;
  font-size: 0.2933rem;
  margin-top: 0.1333rem;
  color: #666666;
}

.noOrder {
  margin: 1.8667rem auto;
  text-align: center;
  font-size: 0.4533rem;
}

.nobuy {
  width: 4.5333rem;
  height: 2.9467rem;
}

.nobuy_order {
  width: 3.4667rem;
  height: 3.3733rem;
}

.tipsText {
  display: block;
  text-align: center;
  color: #9b9b9b;
  font-size: 0.3733rem;
  margin-top: 0.2rem;
}

.addLine {
  text-decoration: underline;
}

.topfixed {
  width: 100%;
  height: 0.6rem;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  color: #fff;
  background-color: #f6b45e;
  text-align: center;
  font-size: 0.3467rem;
  vertical-align: middle;
  line-height: 0.6rem;
  padding: 0.1333rem 0 0.04rem;
}

.goods_success {
  color: #f59a23;
}

.goods_fail {
  color: #d9001b;
}

/* 订单列表 */
.container_order {
  padding-bottom: 2rem;
  margin-top: 5%;
}
.container_role {
  margin-left: 0;
  padding-bottom: 2%;
  background: #fff;
}
.tab_my {
  overflow: hidden;
  animation-duration: 0.4s;
}
.container_order .list-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 96%;
  margin: 0 auto;
  padding: 0.2rem;
  margin-top: 0.24rem;
  text-align: left;
  border: 1px solid #edeef0;
  background: #fff;
  border-radius: 0.0667rem;
  transition: background 0.6s;
  &:hover {
    background: #edeef0;
  }
}

.container_order .list-images {
  margin-right: 0.1rem;
  margin-left: 0.1333rem;
}

.container_order .list-images image {
  width: 2rem;
  height: 2rem;
  border-radius: 0.2667rem;
}

.container_order .list-text {
  font-size: 14px;
  color: #666666;
}

.container_order .list-text .name {
  display: block;
  width: 4.7333rem;
  color: #9b9b9b;
  font-size: 0.3467rem;
  margin-top: 0.24rem;
}

.container_order .list-text .type {
  display: block;
  padding: 0.0667rem 0 0.0667rem 0;
  color: #181818;
  font-size: 0.4133rem;
}

.container_order .list-text .txt {
  padding: 0.0667rem;
  color: #aaaaaa;
}

.container_order .list-text .price {
  font-size: 0.3467rem;
  color: #9b9b9b;
}

.list-images img {
  width: 2.2667rem;
  height: 2.2667rem;
  border-radius: 0.3333rem;
}

.container_role .list-text {
  position: relative;
  font-size: 0.1867rem;
  color: #666666;
}
.container_role .list-text .name {
  display: block;
  width: 6.6667rem;
  padding: 0.0667rem 0;
  color: #181818;
  font-size: 0.4267rem;
}

.container_role .list-text .type {
  display: block;
  width: 6.6667rem;
  padding: 0.2rem 0 0.2rem 0;
  color: #9b9b9b;
  font-size: 0.4rem;
}

.container_role .list-text .text {
  color: #aaaaaa;
  height: 0.7733rem;
  vertical-align: bottom;
  line-height: 0.7733rem;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.3867rem;
}

.container_role .list-text .price {
  font-size: 0.4rem;
  color: #181818;
}

.container_role .list-text .button {
  width: 1.8533rem;
  height: 0.7733rem;
  line-height: 0.7733rem;
  border-radius: 0.08rem;
  font-size: 0.3733rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 0.0267rem solid #ffa019;
  transition: all 0.2s;
  &:hover {
    color: #fff;
    background-color: #ffa019;
  }
}

.loading {
  width: 100%;
  text-align: center;
  font-size: 0.2933rem;
  margin-top: 0.16rem;
  color: #9b9b9b;
}

.container_role .login {
  display: flex;
  align-items: center;
  height: 1.3333rem;
}

.container_role .login .fr {
  margin-left: auto;
  margin-right: 0.2rem;
}

.container_role .login_text {
  padding-top: 0.1333rem;
  font-weight: bold;
}

.container_role .icon_image {
  display: inline-block;
  width: 0.6667rem;
  height: 0.6667rem;
  margin-right: 0.1333rem;
  margin-left: 0.1333rem;
  padding-top: 0.0667rem;
}

.login_out {
  display: block;
  font-size: 0.35rem;
  margin-left: 0.1333rem;
  vertical-align: middle;
  padding: 0 0.2rem;
  border-radius: 0.2667rem;
  margin-left: 0.1333rem;
  position: absolute;
  right: 0.2667rem;
  top: 0.4067rem;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #ffa019;
  }
}

.noOrder {
  margin: 2.1333rem auto;
  text-align: center;
  font-size: 0.4533rem;
}

.noLogin {
  text-align: center;
  height: 3.4rem;
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -4.7rem;
  max-width: 750px;
  min-width: 320px;
}

.noLogin text {
  display: block;
}

.noLogin .title {
  display: block;
  font-size: 0.6133rem;
  margin: 0.6rem 0 0;
}

.nobuy_merge {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.btn_login {
  display: block;
  width: 4rem;
  margin: 0 auto;
  height: 1.0667rem;
  line-height: 1.0667rem;
  border-radius: 0.08rem;
  font-size: 0.4533rem;
  color: #ffff;
  text-align: center;
  background-color: #ffa019;
}

/* 登录页面修改 */
.dialog_wrapper {
  width: 7.4667rem;
  height: 5.5467rem;
  left: 50%;
  top: 50%;
  margin-left: -3.73335rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.2667rem;
  z-index: 100;
}

.dialog_wrapper form {
  width: 6.1333rem;
  height: 2.1333rem;
  border: 0.0267rem solid #ffa019;
  border-radius: 0.1333rem;
  margin: 0 auto;
  position: absolute;
  z-index: 116;
  left: 50%;
  margin-left: -3.08rem;
  top: 4.5333rem;
  .btn_third_login {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.7rem auto;
    width: 3.0533rem;
    height: 0.84rem;
    background: url("../assets/images/btn_third_login.png");
    background-size: 100% 100%;
  }
  a {
    display: inline-block;
    flex: 1;
    height: 100%;
  }
}

.dialog_wrapper form input {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  text-align: left;
  color: #cfa069;
  font-size: 0.435rem;
  margin-left: 0.2667rem;
}

.dialog_wrapper form .account_v,
.dialog_wrapper form .password_v {
  display: flex;
  align-items: center;
  height: 1.0667rem;
  padding: 0 0.2667rem;
}

.dialog_wrapper form .account_v {
  border-bottom: 1px solid #dcbc9a;
}

.dialog_wrapper form .account_v image,
.dialog_wrapper form .password_v image {
  width: 0.4rem;
  height: 0.44rem;
}

.dialog_wrapper form .button {
  width: 100%;
  height: 0.9867rem;
  line-height: 0.9867rem;
  margin-top: 0.4rem;
  color: #ffffff;
  font-size: 0.4267rem;
  border-radius: 5px;
  text-align: center;
  padding: 0;
  border-color: transparent;
  background: #ffa019;
}
</style>
