import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import axios from "axios";
import layer from "vue-layer";
import "vue-layer/lib/vue-layer.css";
import animated from "animate.css";
import "lib-flexible";
import "./assets/css/mb.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { PullRefresh, List, Loading } from "vant";
import "vant/lib/index.css";
import { Popup, Picker } from "mint-ui";
import "mint-ui/lib/style.css";
import utils from "./utils/urlGet";
Vue.component(Popup.name, Popup);
Vue.component(Picker.name, Picker);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Loading);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$layer = layer(Vue);
Vue.prototype.$utils = utils;
Vue.use(animated);
Vue.use(VueAwesomeSwiper);

Vue.prototype.getQueryString = function (name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var l = decodeURI(window.location.href);
  var r = l.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

Vue.prototype.LoginToken = function () {
  axios.create({
    timeout: 5000,
  });
  let token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
  axios.defaults.headers['local'] = 'TW'
};


Vue.prototype.debounce = function (fn, delay) {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn && fn();
    }, delay);
  };
};

Vue.prototype.throttle = (fn, delay) => {
  let lastTime = 0;
  return () => {
    let nowDate = Date.now();
    if (nowDate - lastTime > delay) {
      lastTime = nowDate;
      fn && fn();
    }
  };
};

// let baseUrl = "https://devapi.morefun.zone";
let baseUrl = "https://morebean.gamemorefun.com";
Vue.prototype.postHttp = function (obj, data, address, fn) {
  obj.LoginToken();
  obj.$axios.post(baseUrl + address, data).then(
    (response) => {
      fn(obj, response.data);
    },
    (response) => {
      console.log(response);
    }
  );
};

Vue.prototype.getHttp = function (obj, data, address, fn) {
  obj.LoginToken();
  obj.$axios
    .get(baseUrl + address, {
      params: data,
    })
    .then(function (response) {
      if (response.data.status === 4003) {
        localStorage.removeItem("token");
        localStorage.removeItem("account");
        router.push({ path: "/myInfo" }).catch((err) => {
          console.log(err);
        });
      }
      fn(obj, response.data);
    })
    .catch(function (response) {
      console.log(response);
    });
};

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
