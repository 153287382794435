<template>
  <div class="pay_container">
    <div class="pay_success">
      <img src="../assets/images/suc.png" class="pay_icon" />
      <span class="text t_title">支付成功</span>
      <span class="text t_deliver">正在發貨...</span>
      <div class="btn btn_orders" @click="toOrders">我的訂單</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaySuccess",
  data() {
    return {
      goods_name: "",
    };
  },
  mounted() {
    let status = this.getQueryString("status");
    if (status == 0) {
      this.$router.push("/payError");
    }
  },
  methods: {
    toOrders() {
      this.$router.push("/MyInfo");
    },
  },
};
</script>

<style lang="scss" scoped>
.pay_container {
  height: 100%;
  padding-top: 1.3733rem;
  background: #fff;
}
.pay_success {
  width: 7.2667rem;
  margin: 0 auto;
  text-align: center;
}
.pay_icon {
  width: 2.6267rem;
  height: 2.6267rem;
  margin-bottom: 0.6rem;
}
.text {
  display: block;
}
.t_title {
  color: #181818;
  font-size: 0.6133rem;
}
.t_deliver {
  color: #999;
  font-size: 0.4133rem;
  margin-top: 0.3rem;
}
.t_code {
  margin-top: 0.5067rem;
  margin-bottom: 0.2933rem;
  color: #646464;
  font-size: 0.3733rem;
}
.t_get {
  color: #9b9b9b;
  font-size: 0.3467rem;
}
.btn {
  width: 7.2667rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
  border-radius: 0.1333rem;
  margin-top: 0.7467rem;
}
.btn_customer {
  margin-top: 0.3467rem;
  margin-bottom: 0.2667rem;
  color: #fff;
  background-color: #ffa019;
}
.btn_orders {
  color: #ffa019;
  background-color: #f8f8f8;
  cursor: pointer;
}
</style>