<template>
  <div class="store">
    <McionBanner></McionBanner>
    <div class="list-view scroll-box">
      <mescroll
        ref="mescroll"
        :down="mescrollDown"
        :up="mescrollUp"
        @init="mescrollInit"
      >
        <div class="list-box" v-for="(list, index) in goodsList" :key="index">
          <div class="list-images">
            <img :src="list.goods_img" />
          </div>
          <div class="list-text">
            <span class="name ep-one">{{ list.name }}</span>
            <span class="type ep-one">{{ list.desc }}</span>
            <div class="text">
              <span class="price">${{ list.local_price }}</span>
              <div
                class="button orange"
                v-if="list.is_buy === true"
                @click="handleClickBuy(list)"
              >
                購買
              </div>
              <div class="button gray" v-else>已售罄</div>
            </div>
          </div>
        </div>
      </mescroll>
    </div>

    <div class="dialog_end animated fadeIn" v-if="isSellOut">
      <span class="title">搶完啦</span>
      <span class="tips">該商品庫存不足，請選擇其他商品~</span>
      <div class="btn" @click="closeDialog">確定</div>
    </div>
    <div class="mask" @click="closeDialog" v-if="isReportDialog"></div>
    <div
      class="dialog_report animate__animated animate__bounceIn"
      v-if="isReportDialog"
      :key="2"
    >
      <div class="checkbox" v-if="isDefaultCheckbox">
        <em class="c1" @click="checkedBox(1)" v-if="isChecked"></em>
        <em class="c2" @click="checkedBox(0)" v-else></em>
        <span>{{ cbRole }} {{ cbGame }} {{ cbServer }}</span>
      </div>

      <form>
        <div class="account_v select_game onlyRead" v-if="isDisabled === true">
          <div class="input_game">{{ defaultGameName }}</div>
          <img src="../assets/images/bot.png" class="wx_bot" />
        </div>
        <div class="account_v select_game click_select" v-else>
          <div class="input_game" @click="handleSellPullShow">
            {{ defaultGameName }}
          </div>
          <img src="../assets/images/bot.png" class="wx_bot" />
          <div class="sel_pull" v-if="isSellPull">
            <span
              v-for="(item, index) in gamesList"
              :key="index"
              @click="handlGameChange(item)"
              >{{ item.real_name }}</span
            >
          </div>
        </div>
        <div class="account_v">
          <input
            type="text"
            name="serve"
            placeholder="請填寫區服"
            disabled
            class="onlyRead"
            v-if="isDisabled === true"
          />
          <input
            type="text"
            name="serve"
            placeholder="請填寫區服"
            v-model="roleInfo.server_name"
            v-else
          />
        </div>
        <div class="password_v">
          <input
            type="text"
            name="role"
            placeholder="請填寫角色"
            disabled
            class="onlyRead"
            v-if="isDisabled === true"
          />
          <input
            type="text"
            name="role"
            placeholder="請填寫角色"
            v-model="roleInfo.role_name"
            v-else
          />
        </div>
        <a href="javascript:;" class="button" @click="handlePay">
          <van-loading
            type="spinner"
            color="#ffffff"
            class="p_loading"
            v-if="isLoading"
          />
          立即支付</a
        >
      </form>
    </div>
    <template>
      <mt-popup
        v-model="options.popVisible_game"
        position="bottom"
        :closeOnClickModal="false"
        :lockScroll="true"
      >
        <div class="popup-container" @touchmove.prevent>
          <mt-picker
            :slots="slots_game"
            :showToolbar="true"
            ref="picker_game"
            valueKey="real_name"
          >
            <span
              @click="cancel()"
              class="mint-datetime-action mint-datetime-cancel"
              >取消</span
            >
            <span
              @click="confirm()"
              class="mint-datetime-action mint-datetime-confirm"
              >確認</span
            >
          </mt-picker>
        </div>
      </mt-popup>
    </template>
    <template>
      <mt-popup
        v-model="options.popVisible_payChannel"
        position="bottom"
        :closeOnClickModal="false"
        :lockScroll="true"
      >
        <div class="popup-container" @touchmove.prevent>
          <mt-picker
            :slots="slots"
            :showToolbar="true"
            ref="picker_payChannel"
            valueKey="name"
          >
            <span
              @click="cancelPay()"
              class="mint-datetime-action mint-datetime-cancel"
              >取消</span
            >
            <span
              @click="confirmPay()"
              class="mint-datetime-action mint-datetime-confirm"
              >確認</span
            >
          </mt-picker>
        </div>
      </mt-popup>
    </template>
  </div>
</template>

<script>
import mescroll from "mescroll.js/mescroll.vue";
import McionBanner from "./McionBanner.vue"
export default {
  name: "McionStore",
  components: { mescroll,McionBanner },
  data() {
    return {
      mescroll: null,
      mescrollDown: {
        auto: false,
        textOutOffset: "釋放更新",
        textLoading: "Loading...",
        callback: this.downCallback,
      },
      mescrollUp: {
        callback: this.upCallback,
        page: {
          num: 0,
          size: 6,
        },
        auto: true,
        noMoreSize: 5,
        htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        htmlLoading:
          '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">Loading...</p>',
      },
      goodsList: [],
      page: 1,
      pages: 0,
      refreshing: false,
      loading: false,
      finished: false, // false表示已加载完成，加载完成后不再触发load事件
      loadingMore: false,
      loadingMoreComplete: false,
      isLogined: false, //登录状态
      isSellOut: false, //已售罄,
      isMask: false,
      isReportDialog: false,
      isSellPull: false,
      isDefaultCheckbox: false,
      cbRole: "",
      cbGame: "",
      cbServer: "",
      gamesList: [],
      goods_id: "",
      game_id: "",
      defaultGameName: "請填寫遊戲",
      server_name: "",
      role_name: "",
      use_default: false,
      isDisabled: false, //未选中
      defaultRoleObject: {},
      isChecked: false,
      roleInfo: {},
      game_id_default: "",
      slots: [
        {
          flex: 1,
          values: [],
          className: "slot1",
          textAlign: "center",
        },
      ],
      slots_game: [
        {
          flex: 1,
          values: [],
          className: "slot1",
          textAlign: "center",
        },
      ],
      options: {
        popVisible_payChannel: false,
        popVisible_game: false,
      },
      payChannelList: [],
      isLoading: false,
      payDatas: {},
      payLock: false,
    };
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    downCallback(mescroll) {
      this.goodsList = [];
      mescroll.resetUpScroll();
    },
    upCallback(page) {
      const datas = {
        local: "TW",
        page: page.num,
        page_size: page.size,
      };
      this.getHttp(this, datas, "/h5/goods/list", function (obj, data) {
        if (data.status == 2000) {
          let arr = data.data.data_list;

          if (page.num === 1) obj.goodsList = [];
          obj.goodsList = obj.goodsList.concat(arr);

          obj.$nextTick(() => {
            obj.mescroll.endSuccess(arr.length);
          });
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
    checkedBox(n) {
      if (n == 0) {
        // 选中
        this.isChecked = true;
        this.isDisabled = true;
        this.use_default = true;
        this.defaultGameName = "請填寫遊戲";
        this.game_id = this.game_id_default;
        // this.game_id = '',
        // this.game_id_default = ''
      } else {
        this.isChecked = false;
        this.isDisabled = false;
        this.use_default = false;
      }
    },
    getPayChannel() {
      const datas = {
        game_id: this.game_id
      }
      this.getHttp(this, datas, "/h5/pay/channel", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          if (d.length === 0) { 
            obj.$layer.msg('該遊戲下沒有對應的支付方式');
            return
          }
          obj.options.popVisible_payChannel = true;
          obj.payChannelList = d;
          obj.slots[0].values = obj.payChannelList;
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
    getDefaultRoleInfo() {
      let token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      this.getHttp(this, "", "/h5/role/default", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          if (d.length == 0) {
            obj.isDefaultCheckbox = false;
          } else {
            obj.isDefaultCheckbox = true;
            obj.cbRole = d.role_name;
            obj.game_id = d.game_id;
            obj.cbGame = d.game_name;
            obj.cbServer = d.server_name;
            obj.role_name = d.role_name;
            obj.server_name = d.server_name;
            obj.defaultRoleObject = d;
            obj.game_id_default = d.game_id;
            obj.server_name = d.server_name;
            obj.role_name = d.role_name;
            // obj.roleInfo.server_name = d.server_name;
            // obj.roleInfo.role_name = d.role_name;
          }
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
    handleClickBuy(e) {
      if (localStorage.getItem("token")) {
        this.goods_id = e.id;
        this.isReportDialog = true;
        this.roleInfo.role_name = "";
        this.roleInfo.server_name = "";
        this.getDefaultRoleInfo();
        this.getGamesList();
      } else {
        this.$router.push("/MyInfo");
      }
    },
    cancel() {
      this.options.popVisible_game = false;
    },
    confirm() {
      this.options.popVisible_game = false;
      let values = this.$refs.picker_game.getValues();
      this.game_id = values[0].id;
      this.defaultGameName = values[0].real_name;
    },
    cancelPay() {
      this.options.popVisible_payChannel = false;
      this.payChannelList = "";
      this.slots[0].values = "";
    },
    confirmPay() {
      this.options.popVisible_payChannel = false;
      let values = this.$refs.picker_payChannel.getValues();
      this.payDatas.channel_id = values[0].id;
      this.startPay();
    },
    //點擊'確認'開始支付
    startPay() {
      // http://localhost:8080/#/paySuccess
      // https://store.hermesgames.com.tw/#/paySuccess
      this.isLoading = true;
      this.payLock = true;
      this.payDatas.id = this.goods_id;
      this.payDatas.redirectUrl =
        "https://store.hermesgames.com.tw/#/paySuccess";
      this.postHttp(
        this,
        this.payDatas,
        "/h5/create/order",
        function (obj, data) {
          if (data.status == 2000) {
            let d = data.data;
            if (d.type == "html") {
              const div = document.createElement("div");
              div.innerHTML = d.content;
              document.body.appendChild(div);
              document.forms[document.forms.length - 1].submit();
            } else if (d.type == "url") {
              window.location.href = d.content;
            }
          } else {
            obj.$layer.msg(data.message);
            obj.isLoading = false;
            obj.payLock = false;
          }
        }
      );
    },
    handlePay() {
      let token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/myInfo");
        return false;
      }
      if (this.payLock) {
        return;
      }
      if (this.roleInfo.server_name || this.roleInfo.role_name) {
        this.server_name = this.roleInfo.server_name;
        this.role_name = this.roleInfo.role_name;
      }
      if (this.isDisabled == true) {
        this.payDatas.use_default = this.use_default;
        this.payDatas.game_id = this.game_id_default;
        this.payDatas.server_name = this.server_name;
        this.payDatas.role_name = this.role_name;
      } else {
        if (!this.goods_id) {
          this.$layer.msg("請先選擇商品");
          return false;
        }
        if (!this.game_id) {
          this.$layer.msg("請先選擇遊戲");
          return false;
        }
        if (!this.defaultGameName) {
          this.$layer.msg("遊戲名稱不能為空");
          return false;
        }
        if (!this.roleInfo.server_name) {
          this.$layer.msg("請填寫區服");
          return false;
        }
        if (!this.roleInfo.role_name) {
          this.$layer.msg("請填寫角色");
          return false;
        }
        this.payDatas.use_default = this.use_default;
        this.payDatas.game_id = this.game_id;
        this.payDatas.server_name = this.roleInfo.server_name;
        this.payDatas.role_name = this.roleInfo.role_name;
      }
      this.getPayChannel();
      
    },
    closeDialog() {
      this.isMask = false;
      this.isSellOut = false;
      this.isReportDialog = false;
      this.isSellPull = false;
      this.isLoading = false;
      this.payLock = false;
    },
    handleSellPullShow() {
      // var isShow = this.isSellPull ? false : true;
      // this.isSellPull = isShow;
      this.options.popVisible_game = true;
      this.getGamesList();
    },
    handlGameChange(e) {
      this.game_id = e.id;
      this.defaultGameName = e.real_name;
      this.isSellPull = false;
    },
    showSellOutDialog() {
      this.isSellOut = true;
      this.isMask = true;
    },
    getGamesList() {
      let token = localStorage.getItem("token");
      if (!token) {
        return false;
      }
      const datas = {
        local: "TW"
      }
      this.getHttp(this, datas, "/h5/game", function (obj, data) {
        if (data.status == 2000) {
          obj.gamesList = data.data;
          obj.slots_game[0].values = data.data;
        } else {
          obj.$layer.msg(data.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.store::v-deep .mescroll {
  overflow-x: hidden;
}
.store::v-deep .van-list__finished-text {
  text-align: center;
  font-size: 0.2933rem;
  margin-top: 0.16rem;
  color: #9b9b9b;
}
.store::v-deep .upwarp-tip {
  text-align: center;
}
.click_select {
  cursor: pointer;
}
.scroll-box {
  width: 100%;
  position: fixed;
  top: 2.63rem;
  /*top: 0.4rem;*/
  bottom: 0.6rem;
  height: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 0.6rem;
  overflow-x: hidden;
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  width: 90%;
  margin: 0 auto;
  span {
    display: block;
    flex: 1;
    line-height: 0.5rem;
  }
  em {
    display: inline-block;
    width: 0.533rem;
    height: 0.533rem;
    vertical-align: middle;
    margin-right: 0.1rem;
    cursor: pointer;
    transition: all 0.2s;
    &.c1 {
      background: url("../assets/images/checked.png");
      background-size: 100% 100%;
    }
    &.c2 {
      background: url("../assets/images/checked_.png");
      background-size: 100% 100%;
    }
  }
}
.checkbox input {
  width: 0.5rem;
  height: 0.5rem;
}

input[type="checkbox"]:checked {
  color: #ffa019;
  background-color: #ffa019;
}
.li st-view:nth-of-type(1) {
  border-top: 0;
}

.list-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.2667rem;
  padding: 0.3333rem 0.3333rem;
  border-bottom: 0.02rem solid #eee;
}

.list-box:first-child {
  border-top: 0.02rem solid #eee;
}

.list-images img {
  width: 2.2667rem;
  height: 2.2667rem;
  border-radius: 0.3333rem;
}

.list-text {
  position: relative;
  flex: 1;
  font-size: 0.1867rem;
  color: #666666;
  text-align: left;
}

.list-text .name {
  display: block;
  width: 6.6667rem;
  padding: 0.0667rem 0;
  color: #181818;
  font-size: 0.4267rem;
}

.list-text .type {
  display: block;
  width: 6.6667rem;
  padding: 0.2rem 0 0.2rem 0;
  color: #9b9b9b;
  font-size: 0.3467rem;
}

.list-text .text {
  color: #aaaaaa;
  height: 0.7733rem;
  vertical-align: bottom;
  line-height: 0.7733rem;
}

.list-text .price {
  font-size: 0.4rem;
  color: #181818;
}

.list-text .button {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.38665rem;
  width: 1.8533rem;
  height: 0.7733rem;
  line-height: 0.7733rem;
  border-radius: 0.08rem;
  margin-right: 0.4rem;
  font-size: 0.4rem;
  color: #ffff;
  text-align: center;
  background-color: #ffa019;
  transition: background-color 0.3s;
}

.p_loading {
  position: absolute !important;
  z-index: 999;
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  top: 50%;
  left: 50%;
  margin-left: -1.5rem;
  margin-top: -0.25rem;
  color: #ffffff !important;
}

.dialog_report .button {
  position: relative;
  display: block;
  width: 100%;
  height: 1.0667rem;
  line-height: 1.0667rem;
  border-radius: 0.08rem;
  font-size: 0.4rem;
  color: #ffff;
  text-align: center;
  background-color: #ffa019;
  margin-top: 0.6133rem;
}

.orange {
  cursor: pointer;
}

@media (any-hover: hover) {
  .orange:hover {
    border: 1px solid #ffa019;
    color: #ffa019;
    background-color: #fff;
  }
}

.gray {
  background-color: #959595 !important;
}

.list-images {
  margin-right: 0.4rem;
  border-radius: 0.08rem;
}

.noOrder {
  margin: 0.92rem auto;
  text-align: center;
  font-size: 0.4533rem;
}

.tipsText {
  display: block;
  text-align: center;
  color: #9b9b9b;
}

.container .login {
  display: flex;
  align-items: center;
  height: 1.3333rem;
}

.login_text {
  padding-top: 0.1333rem;
  font-weight: bold;
}

.container .icon_image {
  display: inline-block;
  width: 0.6667rem;
  height: 0.6667rem;
  margin-right: 0.1333rem;
  margin-left: 0.1333rem;
  padding-top: 0.1333rem;
}
.dialog_report {
  position: absolute;
  width: 8.6rem;
  padding: 0.4rem 0 0.2667rem;
  left: 50%;
  top: 50%;
  margin-left: -4.3rem;
  margin-top: -3.6733rem;
  background: rgba(255, 255, 255, 1);
  border: 0.0267rem solid #ffa019;
  border-radius: 0.2667rem;
  padding-bottom: 1rem;
  z-index: 100;
}

.dialog_report form {
  width: 90%;
  border-radius: 0.1333rem;
  margin: 0 auto;
}
.wx_check {
  margin-left: -7rpx;
}

.dialog_report form input {
  display: block;
  width: 90.5%;
  height: 1.0667rem;
  text-align: left;
  color: #cfa069;
  font-size: 0.435rem;
  border: 0.0267rem solid #ffa019;
  border-radius: 0.1067rem;
  margin-top: 0.5333rem;
  padding-left: 0.7333rem;
}

.input_game {
  width: 90.5%;
  height: 1.0667rem;
  line-height: 1.0667rem;
  text-align: left;
  color: #cfa069;
  font-size: 0.435rem;
  border: 0.0267rem solid #ffa019;
  border-radius: 0.1067rem;
  margin-top: 0.5333rem;
  padding-left: 0.7333rem;
}

.select_game {
  position: relative;
}

.select_arrow {
  position: absolute;
  top: 0.1333rem;
  right: 0.1333rem;
  font-size: 0.4rem;
  color: #ffa019;
  transform: rotate(90deg);
}

.checkbox {
  font-size: 0.4267rem;
}

.sel_pull {
  position: absolute;
  z-index: 9;
  left: 50%;
  width: 96%;
  margin-left: -48%;
  font-size: 0.4rem;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0.1067rem;
  transition: all 0.9s ease;
  box-sizing: content-box;
}

.sel_pull span {
  width: 86%;
  margin: 0 auto;
  height: 0.9067rem;
  line-height: 0.9067rem;
  border-bottom: 1px solid #eee;
  display: block;
  padding-left: 0.5867rem;
  cursor: pointer;
}

.sel_pull text:last-child {
  border-bottom: none;
}
.onlyRead {
  position: relative;
  background: #eee;
}
/* 重写checkbox 样式 */
checkbox .wx-checkbox-input {
  width: 0.5333rem;
  height: 0.5333rem;
}
.wx_bot {
  width: 0.4667rem;
  height: 0.2133rem;
  position: absolute;
  right: 0.2667rem;
  top: 50%;
  margin-top: -0.1067rem;
}
.isNodata {
  height: 6.6267rem;
  padding: 0.3067rem 0.2667rem 0.4rem 0.5333rem;
}
/* 搶完啦彈窗 */
.dialog_end {
  position: fixed;
  width: 6.6667rem;
  height: 4rem;
  left: 50%;
  top: 50%;
  margin-left: -3.3333rem;
  margin-top: -2rem;
  z-index: 100;
  line-height: 1.3333rem;
  background: rgba(255, 255, 255, 1);
  border: 2rpx solid #ffa019;
  border-radius: 0.1333rem;
  text-align: center;
}

.dialog_end text {
  display: block;
}

.dialog_end .title {
  font-size: 0.4667rem;
}

.dialog_end .tips {
  font-size: 0.3467rem;
  color: #a9a9a9;
}

.dialog_end .btn {
  color: #ffa019;
  border-top: 1px solid #e5e5e5;
}
</style>
